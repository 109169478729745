<template>
  <div>
    <v-row class="mt-10">
      <v-col cols="2">
        <v-text-field
          label="Schedule"
          type="number"
          min="1"
          max="31"
          outlined
          dense
          v-model="schedule"
        />
      </v-col>
      <v-col>
        <v-btn
          :loading="gettingAutopays"
          :disabled="gettingAutopays"
          color="primary"
          @click="loadAutopays"
        >
          Get AutoPays for {{ schedule }}
        </v-btn>
      </v-col>
    </v-row>

    <v-simple-table v-if="autopays.length">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left" v-for="header in headers" :key="header">
              {{ header }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="autopay in autopays" :key="autopay.id">
            <td>{{ autopay.id }}</td>
            <td>{{ autopay.customer_id }}</td>
            <td>{{ autopay.patient_id }}</td>
            <td>{{ autopay.batch_is_active }}</td>
            <td>{{ formatDate(autopay.last_payment_date) }}</td>
            <td>{{ formatDate(autopay.next_payment_date) }}</td>
            <td>{{ autopay.schedule }}</td>
            <td>
              <v-btn
                :loading="runningAutopay"
                :disabled="runningAutopay"
                color="primary"
                @click="reRunNow(autopay.id)"
              >
                Rerun Autopay
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div v-else class="mt-10" align="center">
      No Missed AutoPays this month for this schedule
    </div>
  </div>
</template>

<script>
import * as moment from 'moment-timezone';

export default {
  name: 'Activity',
  data() {
    return {
      schedule: '1',
      gettingAutopays: false,
      runningAutopay: false,
      autopays: [],
      localTZ: 'UTC',
    };
  },
  computed: {
    headers() {
      return [
        'id',
        'customer_id',
        'patient_id',
        'batch_is_active',
        'last_payment_date',
        'next_payment_date',
        'schedule',
        'action',
      ];
    },
  },
  mounted() {
    this.setLocalTimezone();
  },
  methods: {
    formatDate(date) {
      if (!date) return '';
      else return moment(date).tz(this.localTZ).format('YYYY-MM-DD HH:mm');
    },
    setLocalTimezone() {
      this.localTZ = moment.tz.guess();
    },
    async loadAutopays() {
      if (this.schedule > 31 || this.schedule < 1) return;

      try {
        this.gettingAutopays = true;
        const url = '/proxy/fetch-missed-autopays';
        const response = await window.axios.post(url, {
          schedule: this.schedule,
        });
        if (response.data && response.data.success === true) {
          this.autopays = response.data.result;
        } else if (response.data && response.data.message) {
          throw { message: response.data.message };
        }
      } catch (error) {
        this.$store.dispatch('notifications/setError', error.message, {
          root: true,
        });
      } finally {
        this.gettingAutopays = false;
      }
    },
    async reRunNow(batchId) {
      if (!window.confirm('Are you sure want to run this batch manually?')) {
        return;
      }
      try {
        this.runningAutopay = true;
        const url = '/proxy/rerun-autopay';
        const response = await window.axios.post(url, { batchId });
        if (response.data && response.data.success === true) {
          this.$store.dispatch(
            'notifications/pushNotification',
            'Autopay Started',
            {
              root: true,
            }
          );
        } else if (response.data && response.data.message) {
          throw { message: response.data.message };
        }
      } catch (error) {
        this.$store.dispatch('notifications/setError', error.message, {
          root: true,
        });
      } finally {
        this.runningAutopay = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
